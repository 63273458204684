const STORE_STATUS_DELETED = 0;
const STORE_STATUS_WAIT = 1;
const STORE_STATUS_APPROVED = 2;
const STORE_STATUS_BANNED = 3;

const STATUS = {
  DELETED: STORE_STATUS_DELETED,
  WAIT: STORE_STATUS_WAIT,
  APPROVED: STORE_STATUS_APPROVED,
  BANNED: STORE_STATUS_BANNED,
} as const;

export const STORE = {
  STATUS,
};
