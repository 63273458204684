import React, { useCallback, useEffect, useState } from 'react';

export type GPSAddress = {
  address_name: string;
  address_type: string;
  x: string;
  y: string;
};

type Props = {
  address: string;
  lat?: number;
  lon?: number;
  onMarkerSelected(place: GPSAddress): void;
  mapSize: number;
};

const { kakao } = window;
let map: any;
let geocoder: any;

export const KaKaoMapGPSSearch: React.FC<Props> = ({ address, lat, lon, onMarkerSelected, mapSize }) => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const displayMarker = useCallback(
    (address: GPSAddress) => {
      if (!map || !address) return;

      let marker = new kakao.maps.Marker({
        map: map,
        position: new kakao.maps.LatLng(+address.y, +address.x),
      });

      const infoWindow = new kakao.maps.InfoWindow({
        content: '<div style="padding:5px;font-size:12px;">' + address.address_name + '</div>',
        position: marker.getPosition(),
      });
      infoWindow.open(map, marker);

      // 마커에 클릭이벤트를 등록합니다
      kakao.maps.event.addListener(marker, 'click', function () {
        onMarkerSelected(address);
      });
    },
    [onMarkerSelected],
  );

  const addressSearch = useCallback(
    (data: any[], status: any) => {
      if (status === kakao.maps.services.Status.OK) {
        // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
        // LatLngBounds 객체에 좌표를 추가합니다
        let bounds = new kakao.maps.LatLngBounds();

        data.forEach((prop) => {
          displayMarker(prop);
          bounds.extend(new kakao.maps.LatLng(prop.y, prop.x));
        });

        // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
        map?.setBounds(bounds);
      }
    },
    [displayMarker],
  );

  const onDebounceSearch = () => {
    if (address.length <= 1) return;
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      geocoder.addressSearch(address, addressSearch);
    }, 1000);
    setTimer(newTimer);
  };

  useEffect(() => {
    onDebounceSearch();
  }, [address]);

  useEffect(() => {
    const options = {
      center: new kakao.maps.LatLng(37.55383813, 126.9115449),
      level: 3,
    };

    const container = document.getElementById('myMap');
    map = container && new kakao.maps.Map(container, options);
    geocoder = new kakao.maps.services.Geocoder();
  }, []);

  return (
    <div
      id="myMap"
      style={{
        width: mapSize,
        height: mapSize,
      }}
    />
  );
};
