import React from 'react';
import { LeftSideBar } from './layout/LeftSideBar';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { HeaderCrumb } from './layout/HeaderCrumb';
import { Layout } from 'antd';
import { RequireAuth } from './RequireAuth';

const StyledContent = styled(Layout.Content)`
  background: #ffffff;
  border-radius: 10px;
  margin: 25px;
  padding: 10px;
`;

export const MainLayout: React.FC = () => (
  <RequireAuth>
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <LeftSideBar />
      <Layout>
        <HeaderCrumb />
        <StyledContent>
          <Outlet />
        </StyledContent>
        <Layout.Footer />
      </Layout>
    </Layout>
  </RequireAuth>
);
