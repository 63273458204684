import React, { ChangeEvent, ReactNode, useCallback, MouseEvent } from 'react';
import { Button, Form, Input, message, Popconfirm, Select, Switch } from 'antd';
import styled from 'styled-components';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { StoreForm } from './types';
import { GPSAddress, KaKaoMapGPSSearch } from '../KaKaoMapGPSSearch';
import { deleteStore, putStore } from '../../APIs/storeAPI';
import { useStoreTypes } from '../../module/hooks/useStoreTypes';
import { useStoreCategories } from '../../module/hooks/useStoreCategories';

type Props = {
  form: FormInstance;
  onSaveCallback?(): void;
  onCancel?(): void;
  onDeleteCallback?(): void;
  onValueChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onMarkerSelected(address: GPSAddress): void;
  onParkingChange?(checked: boolean, event: MouseEvent<HTMLElement>): void;
  onOperationChange?(operation: string[]): void;
  submitTitle?: string;
  mapSize?: number;
  store: StoreForm;
  imageComponent?: ReactNode;
  categoryIds: number[];
  typeId: number;
  onCategoryChange?(value: number[]): void;
  onTypeChange?(value: number): void;
};

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubmitFinishWrapper = styled.div`
  & > :first-child {
    margin-right: 5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const LeftContainer = styled.div`
  width: 30%;
  margin-right: 30px;
`;

const RightContainer = styled.div``;

const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
`;

export const StoreUpdateForm: React.FC<Props> = ({
  form,
  onSaveCallback,
  onCancel,
  submitTitle = '등록',
  onDeleteCallback,
  onValueChange,
  onMarkerSelected,
  onParkingChange,
  onOperationChange,
  store,
  mapSize = 500,
  imageComponent,
  typeId,
  categoryIds,
  onCategoryChange,
  onTypeChange,
}) => {
  const { types, isTypesFetching } = useStoreTypes();
  const { categories, isCategoriesFetching } = useStoreCategories();

  const onFormChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onValueChange(e), [onValueChange]);

  const onOperationFormChange = useCallback(
    (text: string, index: number) => {
      const nextOperation = [...store.operations];

      nextOperation[index] = text;
      onOperationChange?.(nextOperation);
    },
    [store, onOperationChange],
  );

  const onStoreTypeChange = useCallback(
    (value: number) => {
      onTypeChange?.(value);
    },
    [onTypeChange],
  );

  const onStoreCategoryChange = useCallback(
    (values: number[]) => {
      onCategoryChange?.(values);
    },
    [onCategoryChange],
  );

  const onStoreSave = useCallback(async () => {
    if (!store) {
      return;
    }

    try {
      await putStore(store.id, {
        address: store.address,
        blog: store.blog,
        description: store.description,
        instagram: store.instagram,
        isParking: store.isParking,
        latitude: +store.latitude,
        longitude: +store.longitude,
        name: store.name,
        operations: store.operations,
        phone: store.phone,
        webSite: store.webSite,
        categoryIds,
        typeIds: [typeId],
        s3_thumbnail: store.s3_thumbnail ? store.s3_thumbnail : undefined,
      });
      message.success('등록 되었습니다');
      onSaveCallback?.();
    } catch (e) {
      message.error('등록에 실패하였습니다');
    }
  }, [store, categoryIds, typeId]);

  const onStoreDelete = useCallback(async () => {
    try {
      await deleteStore(store.id);
      message.success('삭제 되었습니다');
      onDeleteCallback?.();
    } catch (e) {
      message.error('삭제에 실패하였습니다');
    }
  }, []);

  return (
    <Container>
      <LeftContainer>
        <Form layout="horizontal" form={form} labelCol={{ sm: 5 }} onFinish={onStoreSave} wrapperCol={{ sm: 30 }}>
          <Form.Item label="매장명" rules={[{ required: true }]}>
            <Input name="name" value={store.name} onChange={onFormChange} />
          </Form.Item>
          <Form.Item label="업종분류">
            <Select
              style={{
                width: '100%',
              }}
              defaultValue={typeId}
              onChange={onStoreTypeChange}
              loading={isTypesFetching}
            >
              {types.map(({ id, name }) => (
                <Select.Option key={`store-type-${id}`} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="키워드">
            <Select mode="multiple" showArrow onChange={onStoreCategoryChange} defaultValue={categoryIds} loading={isCategoriesFetching}>
              {categories.map(({ id, name }) => (
                <Select.Option key={`store-category-${id}`} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="상세설명">
            <Input.TextArea name="description" value={store.description} rows={3} onChange={onFormChange} />
          </Form.Item>
          <Form.Item label="주소" rules={[{ required: true }]}>
            <Input name="address" value={store.address} onChange={onFormChange} />
          </Form.Item>
          <Form.Item label="위도" name="latitude">
            <div>
              <Input disabled name="latitude" value={store.latitude} />
              <input type="text" name="latitude" value={store.latitude} onChange={onFormChange} hidden={true} />
            </div>
          </Form.Item>
          <Form.Item label="경도" name="longitude">
            <div>
              <Input disabled name="longitude" value={store.longitude} />
              <input type="text" name="latitude" value={store.latitude} onChange={onFormChange} hidden={true} />
            </div>
          </Form.Item>
          <Form.Item label="운영시간" style={{ display: 'flex', alignItems: 'center' }}>
            {['평일', '토요일', '일요일', '휴무일'].map((operation, index) => (
              <Form.Item key={operation} label={operation}>
                <Input
                  value={store.operations[index]}
                  onChange={(e) => {
                    onOperationFormChange(e.target.value, index);
                  }}
                />
              </Form.Item>
            ))}
          </Form.Item>
          <Form.Item label="연락처">
            <Input name="phone" value={store.phone} onChange={onFormChange} />
          </Form.Item>
          <Form.Item label="웹사이트">
            <Input name="webSite" value={store.webSite} onChange={onFormChange} />
          </Form.Item>
          <Form.Item label="인스타그램">
            <Input name="instagram" value={store.instagram} onChange={onFormChange} />
          </Form.Item>
          <Form.Item label="블로그">
            <Input name="blog" value={store.blog} onChange={onFormChange} />
          </Form.Item>
          <Form.Item label="주차">
            <Switch checked={store.isParking} onClick={onParkingChange} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <SubmitWrapper>
              <SubmitFinishWrapper>
                <Button type="primary" htmlType="submit">
                  {submitTitle}
                </Button>
                <Button onClick={onCancel}>취소</Button>
              </SubmitFinishWrapper>
              <Popconfirm title="정말 삭제하시겠습니까?" onConfirm={onStoreDelete} okText="네" cancelText="아니오">
                <Button danger>삭제</Button>
              </Popconfirm>
            </SubmitWrapper>
          </Form.Item>
        </Form>
      </LeftContainer>
      <RightContainer>
        <ImageWrapper>{imageComponent}</ImageWrapper>
        <KaKaoMapGPSSearch address={store.address} onMarkerSelected={onMarkerSelected} mapSize={mapSize} />
      </RightContainer>
    </Container>
  );
};
