import React, { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StoreResponseV1, useFetchStoreQuery } from '../../../APIs/storeAPI';
import { StoreUpdateForm } from '../../../components/store/StoreUpdateForm';
import { Form, Image } from 'antd';
import { storeFormAdapter } from '../../../components/store/storeFormAdapter';
import { GPSAddress } from '../../../components/KaKaoMapGPSSearch';
import { baseURL } from '../../../APIs/API';
import { Uploader } from '../../../components/uploader';

export const StoreDetailTab: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const [store, setStore] = useState<StoreResponseV1>();
  const [typeId, setTypeId] = useState<number>(0);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);

  useFetchStoreQuery(id, (res) => {
    setCategoryIds(res.storeCategories.map(({ category: { id } }) => id));
    setTypeId(res.storeTypeLists[0].type.id);
    setStore(res);
  });

  const onValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      store && setStore({ ...store, [e.target.name]: e.target.value });
    },
    [store],
  );

  const onMarkerSelected = useCallback(
    (address: GPSAddress) => {
      store && setStore({ ...store, latitude: address.y, longitude: address.x });
    },
    [store],
  );

  const onCategoryChange = useCallback((value: number[]) => {
    setCategoryIds(value);
  }, []);

  const onTypeChange = useCallback((value: number) => {
    setTypeId(value);
  }, []);

  const onOperationChange = useCallback(
    (operations: string[]) => {
      store && setStore({ ...store, operations });
    },
    [store],
  );

  const onParkingChange = useCallback(
    (checked: boolean, event: MouseEvent<HTMLElement>) => {
      store && setStore({ ...store, isParking: checked });
    },
    [store],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, []);

  const onThumbnailChange = useCallback(
    (path: string) => {
      store && setStore({ ...store, s3_thumbnail: path });
    },
    [store],
  );

  if (!store) {
    return null;
  }

  return (
    <StoreUpdateForm
      form={form}
      onSaveCallback={goBack}
      onDeleteCallback={goBack}
      onOperationChange={onOperationChange}
      onParkingChange={onParkingChange}
      submitTitle="수정"
      onCancel={goBack}
      onMarkerSelected={onMarkerSelected}
      onValueChange={onValueChange}
      store={storeFormAdapter(store)}
      imageComponent={
        <>
          {(store.s3_thumbnail || store.thumbnail) && (store.s3_thumbnail ? <Image src={store.s3_thumbnail} width={300} /> : <Image src={`${baseURL}/${store.thumbnail}`} width={300} />)}
          <Uploader actionUrl={`${baseURL}/file/store`} onUploadedImageCallback={onThumbnailChange} />
        </>
      }
      categoryIds={categoryIds}
      typeId={typeId}
      onCategoryChange={onCategoryChange}
      onTypeChange={onTypeChange}
    />
  );
};
