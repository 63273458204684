import React, { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StoreResponseV1, useFetchStoreQuery } from '../../../../APIs/storeAPI';
import styled from 'styled-components';
import { GPSAddress } from '../../../../components/KaKaoMapGPSSearch';
import { Form, Image } from 'antd';
import { StoreUpdateForm } from '../../../../components/store/StoreUpdateForm';
import { storeFormAdapter } from '../../../../components/store/storeFormAdapter';
import { baseURL } from '../../../../APIs/API';
import { Uploader } from '../../../../components/uploader';

const Container = styled.div``;

export const StoreRequestDetail: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const [store, setStore] = useState<StoreResponseV1>();
  const [categoryIds, setCategoryIds] = useState<number[]>([]);
  const [typeId, setTypeId] = useState(0);

  useFetchStoreQuery(id, (res) => {
    setCategoryIds(res.storeCategories.map(({ category: { id } }) => id));
    setTypeId(res.storeTypeLists[0].type.id);
    setStore(res);
  });

  const onValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      store && setStore({ ...store, [e.target.name]: e.target.value });
    },
    [store],
  );

  const onParkingChange = useCallback(
    (checked: boolean, e: MouseEvent<HTMLElement>) => {
      store && setStore({ ...store, isParking: checked });
    },
    [store],
  );

  const onOperationChange = useCallback(
    (nextOperation: string[]) => {
      store && setStore({ ...store, operations: nextOperation });
    },
    [store],
  );

  const onMarkerSelected = useCallback(
    (address: GPSAddress) => {
      store && setStore({ ...store, latitude: address.y, longitude: address.x });
    },
    [store],
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, []);

  const onCategoryChange = useCallback((values: number[]) => setCategoryIds(values), []);

  const onTypeChange = useCallback((value: number) => setTypeId(value), []);

  const onThumbnailChange = useCallback(
    (path: string) => {
      store && setStore({ ...store, s3_thumbnail: path });
    },
    [store],
  );

  if (!store) {
    return null;
  }

  return (
    <Container>
      <StoreUpdateForm
        form={form}
        onSaveCallback={goBack}
        onDeleteCallback={goBack}
        onValueChange={onValueChange}
        onMarkerSelected={onMarkerSelected}
        onParkingChange={onParkingChange}
        onOperationChange={onOperationChange}
        onCancel={goBack}
        store={storeFormAdapter(store)}
        categoryIds={categoryIds}
        typeId={typeId}
        onCategoryChange={onCategoryChange}
        onTypeChange={onTypeChange}
        imageComponent={
          <>
            {(store.s3_thumbnail || store.thumbnail) && (store.s3_thumbnail ? <Image src={store.s3_thumbnail} width={300} /> : <Image src={`${baseURL}/${store.thumbnail}`} width={300} />)}
            <Uploader actionUrl={`${baseURL}/file/store`} onUploadedImageCallback={onThumbnailChange} />
          </>
        }
      />
    </Container>
  );
};
