import React from 'react';
import { Breadcrumb, Button, Dropdown, Layout, Menu } from 'antd';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../module/user';
import { LoginOutlined, UserOutlined } from '@ant-design/icons';

const { Header } = Layout;

const StyledHeader = styled(Header)`
  background: #f0f2f5;
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
`;

const StyledBreadCrumb = styled(Breadcrumb)`
  margin: 16px 0;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > :first-child {
    margin-right: 5px;
  }
`;

const DropDownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DropDownLogoutText = styled.span`
  margin-left: 5px;
`;

const DropdownLogout = () => {
  const { onUserLogout } = useUser();

  return (
    <DropDownWrapper onClick={onUserLogout}>
      <LoginOutlined />
      <DropDownLogoutText>로그아웃</DropDownLogoutText>
    </DropDownWrapper>
  );
};

const menu = (
  <Menu
    items={[
      {
        key: '1',
        label: <DropdownLogout />,
      },
    ]}
  />
);

export const HeaderCrumb: React.FC = () => {
  const { pathname } = useLocation();
  const { user } = useUser();

  return (
    <StyledHeader>
      <StyledBreadCrumb>{pathname.split('/').map((path) => path && <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>)}</StyledBreadCrumb>
      <Dropdown trigger={['click']} overlay={menu} placement="bottomRight">
        <Profile>
          <UserOutlined />
          <span>{`${user?.nickName}(${user?.email})`}</span>
        </Profile>
      </Dropdown>
    </StyledHeader>
  );
};
