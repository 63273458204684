import { atom, useRecoilState } from 'recoil';
import { PutLoginResponse } from '../APIs/userAPI';
import { setAuthorizationHeader } from '../APIs/API';

type User = PutLoginResponse;

export const useUser = () => {
  const [user, setUser] = useRecoilState(userState);

  const onUserLogin = (params: User) => {
    setUser(params);
  };

  const onUserLogout = () => {
    setUser(null);
    setAuthorizationHeader(null);
  };

  return {
    user,
    onUserLogin,
    onUserLogout,
  };
};

const userState = atom<User | null>({
  key: 'user',
  default: null,
});
