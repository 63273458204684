import { Navigate, useLocation } from 'react-router-dom';
import { setAuthorizationHeader } from '../APIs/API';
import { useUser } from '../module/user';
import { ReactElement } from 'react';

type Props = {
  children: ReactElement;
};

export const RequireAuth = ({ children }: Props) => {
  const { user } = useUser();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
