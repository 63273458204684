import { getStore, getStores, GetStoresParams, UseStoreQueryCallback } from './index';
import { useQuery } from '@tanstack/react-query';

export const useFetchStoresQuery = (params: GetStoresParams) => useQuery(['store', 'list', { ...params }], () => getStores(params));

export const useFetchStoreQuery = (storeId: string | undefined, callback?: UseStoreQueryCallback) =>
  useQuery(['store', 'detail', storeId], async () => {
    if (!storeId) {
      return;
    }
    const res = await getStore(+storeId);
    callback?.(res);

    return res;
  });
