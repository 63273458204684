import React, { ReactNode } from 'react';
import Logo from '../../assets/icons/Logo.svg';
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import { ContainerOutlined, DesktopOutlined, MailOutlined, PieChartOutlined } from '@ant-design/icons';
import { SideBarNavigator } from './types';
import { paths } from '../../pages/paths';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;

const LogoContainer = styled.div`
  height: 64px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px;
`;

const LogoImage = styled.img`
  width: 48px;
  height: 48px;
`;

const LogoText = styled.span`
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
`;

const getItem = (label: string, key: string, icon?: ReactNode, children?: SideBarNavigator[]) => {
  return {
    key,
    icon,
    children,
    label,
  };
};

const navigators: SideBarNavigator[] = [
  getItem('대시보드', paths.dashboard, <PieChartOutlined />),
  getItem('매장관리', '', <DesktopOutlined />, [getItem('매장', paths.store.root), getItem('등록요청', paths.store.request), getItem('수정요청', paths.store.modify)]),
  getItem('고객센터', '3', <ContainerOutlined />),
  getItem('공지사항', 'sub1', <MailOutlined />),
];

export const LeftSideBar: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Sider>
      <LogoContainer>
        <LogoImage src={Logo} alt="logo" />
        <LogoText>곰발바닥</LogoText>
      </LogoContainer>
      <Menu
        defaultSelectedKeys={['1']}
        mode="inline"
        items={navigators}
        style={{
          height: '100%',
        }}
        onClick={(props) => navigate(props.key)}
      />
    </Sider>
  );
};
