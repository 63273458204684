import React from 'react';
import styled from 'styled-components';
import { getStoreModify } from '../../../APIs/storeAPI';
import { Table } from 'antd';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';

const Container = styled.div``;

export const StoreModify: React.FC = () => {
  const { data, isLoading } = useQuery(['store', 'modify', 'list'], getStoreModify, { initialData: [] });

  return (
    <Container>
      <Table dataSource={data} loading={isLoading} pagination={false}>
        <Table.Column key="id" dataIndex="id" title="ID" />
        <Table.Column
          key="requests"
          dataIndex="requests"
          title="요청사항"
          render={(value: string[]) => (
            <div>
              {value.map((request) => (
                <p style={{ margin: 0 }}>{request}</p>
              ))}
            </div>
          )}
        />
        <Table.Column key="description" dataIndex="description" title="상세설명" />
        <Table.Column key="createdAt" dataIndex="createdAt" title="등록날자" render={(value) => moment(value).format('YYYY-MM-DD')} />
      </Table>
    </Container>
  );
};
