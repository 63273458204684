import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Store } from './store';
import { StoreDetail } from './store/Detail';
import { StoreRequest } from './store/Request';
import { StoreModify } from './store/Modify';
import { StoreRequestDetail } from './store/Request/detail';
import { Privacy } from './privacy';
import { PageNotFound } from './PageNotFound';
import { Dashboard } from './dashboard';
import { StoreCreate } from './store/Create';
import { paths } from './paths';
import { Login } from './Login';
import { MainLayout } from '../components/MainLayout';

export const Main = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route element={<MainLayout />}>
        <Route path="/dashboard" element={<Navigate replace to="/" />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/store" element={<Store />} />
        <Route path="/store/:id" element={<StoreDetail />} />
        <Route path={paths.store.create} element={<StoreCreate />} />
        <Route path={paths.store.request} element={<StoreRequest />} />
        <Route path={paths.store.modify} element={<StoreModify />} />
        <Route path="/store/request/:id" element={<StoreRequestDetail />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
