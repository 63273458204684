import React, { useState } from 'react';
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useUser } from '../../module/user';

type FileUploadResponse = {
  createdAt: string;
  id: number;
  name: string;
  originalName: string;
  path: string;
  size: number;
  status: number;
  type: string;
  updatedAt: string | null;
};

const beforeUpload = (file: any) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.warning('이미지 파일만 업로드 할 수 있습니다.');
    return;
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.warning('이미지 파일이 너무 큽니다');
    return;
  }

  return isJpgOrPng && isLt2M;
};

const getBase64 = (img: any, callback: (url: string | ArrayBuffer | null) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

type Props = {
  actionUrl: string;
  multiUpload?: boolean;
  onUploadedImageCallback(path: string): void;
};

export const Uploader: React.FC<Props> = ({ actionUrl, multiUpload = false, onUploadedImageCallback }) => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);

  const handleChange = (info: UploadChangeParam<UploadFile<FileUploadResponse>>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'error') {
      message.warning('이미지 등록에 실패하였습니다.');
      setLoading(false);
    }

    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url: string | ArrayBuffer | null) => {
        info.file.response && onUploadedImageCallback(info.file.response.path);
        setLoading(false);
      });
    }
  };

  return (
    <Upload name="file" onChange={handleChange} listType="picture-card" beforeUpload={beforeUpload} showUploadList={multiUpload} action={actionUrl} headers={{ Authorization: `Bearer ${user?.accessToken || ''}` }}>
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}

        <div
          style={{
            marginTop: 8,
          }}
        >
          이미지 업로드
        </div>
      </div>
    </Upload>
  );
};
