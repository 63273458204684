import React, { Key, useCallback, useEffect, useState } from 'react';
import { getStoreReviews, ImageV1, StoreReviewV1, UserV1 } from '../../../APIs/storeReviewAPI';
import { useParams } from 'react-router-dom';
import { Image, Layout, message, Pagination, Table } from 'antd';
import { baseURL } from '../../../APIs/API';
import moment from 'moment';
import styled from 'styled-components';
import { Paging } from '../index';
import { PER_PAGE } from '../../../shared/pagination';

const ImageContainer = styled.div`
  & > :not(:last-child) {
    margin-right: 5px;
  }
`;

const LayoutHeader = styled(Layout.Header)`
  background-color: #ffffff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LayoutBody = styled(Layout.Content)``;

const LayoutFooter = styled(Layout.Footer)`
  background-color: #ffffff;
`;

export const StoreReviewTab: React.FC = () => {
  const { id } = useParams();

  const [storeReviews, setStoreReviews] = useState<StoreReviewV1[]>([]);
  const [paging, setPaging] = useState({
    take: PER_PAGE,
    skip: 1,
  });
  const [checked, setChecked] = useState<number[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const onChecked = useCallback((selectedRowKeys: Key[], selectedRows: StoreReviewV1[]) => setChecked(selectedRowKeys as number[]), []);

  const onFetchStoreReviews = useCallback(
    async (nextPaging: Paging) => {
      if (!id) return;

      setLoading(true);
      try {
        const res = await getStoreReviews(+id, { skip: nextPaging.skip - 1, take: nextPaging.take });
        setStoreReviews(res.storeReviews);
        setPaging(nextPaging);
        setTotal(res.meta.total);
      } catch (e) {
        message.error('에러가 발생했습니다. 잠시 후에 다시 시도해주세요');
      } finally {
        setLoading(false);
      }
    },
    [id],
  );

  useEffect(() => {
    onFetchStoreReviews(paging);
  }, []);

  return (
    <Layout>
      <LayoutHeader>
        <div />
        <span>총 리뷰 수: {total}</span>
      </LayoutHeader>
      <LayoutBody>
        <Table
          pagination={false}
          dataSource={storeReviews.map((storeReview) => {
            return { ...storeReview, key: storeReview.id, createdAt: moment(storeReview.createdAt).format('YYYY-MM-DD') };
          })}
          rowSelection={{ type: 'checkbox', onChange: onChecked }}
          loading={loading}
        >
          <Table.Column key="user" dataIndex="user" title="사용자" width={250} render={(value: UserV1) => <span>{`${value.nickName}(${value.email})`}</span>} />
          <Table.Column
            key="images"
            dataIndex="images"
            title="이미지"
            render={(value: ImageV1[]) => (
              <Image.PreviewGroup>
                <ImageContainer>
                  {value.map((image) => (
                    <Image width={100} src={`${baseURL}/${image.path}`} key={image.id} />
                  ))}
                </ImageContainer>
              </Image.PreviewGroup>
            )}
          />
          <Table.Column key="description" dataIndex="description" title="내용" width={400} ellipsis={true} />
          <Table.Column key="rate" dataIndex="rate" title="평점" width={100} />
          <Table.Column key="createdAt" dataIndex="createdAt" title="등록일자" width={150} />
        </Table>
      </LayoutBody>
      <LayoutFooter>
        <Pagination current={paging.skip} total={total} pageSize={paging.take} onChange={(skip, take) => onFetchStoreReviews({ skip, take })} />
      </LayoutFooter>
    </Layout>
  );
};
