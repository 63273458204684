import { useCallback, useState } from 'react';
import { STORE, useFetchStoresQuery } from '../../../APIs/storeAPI';
import moment from 'moment';
import { paths } from '../../paths';
import { StoresListTable } from '../../../components/stores/StoresListTable';
import { PER_PAGE } from '../../../shared/pagination';
import { Paging } from '../index';
import { Layout, Pagination } from 'antd';
import styled from 'styled-components';

const LayoutHeader = styled(Layout.Header)`
  background-color: #ffffff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StoreRequest: React.FC = () => {
  const [filterPage, setFilterPage] = useState<Paging>({
    take: PER_PAGE,
    skip: 1,
  });

  const { isLoading, data } = useFetchStoresQuery({ name: '', status: STORE.STATUS.WAIT, ...filterPage });

  const onStorePageSearch = useCallback((paging: Paging) => {
    setFilterPage(paging);
  }, []);

  if (!data?.stores || !data?.meta) {
    return null;
  }

  return (
    <Layout>
      <LayoutHeader>
        <span>전체 매장 수: {data.meta.total}</span>
      </LayoutHeader>
      <Layout.Content>
        <StoresListTable
          stores={data.stores.map((store) => {
            return { ...store, key: store.id, createdAt: moment(store.createdAt).format('YYYY-MM-DD') };
          })}
          path={paths.store.request}
          loading={isLoading}
        />
      </Layout.Content>
      <Layout.Footer>
        <Pagination current={filterPage.skip} pageSize={filterPage.take} total={data.meta.total} onChange={(skip, take) => onStorePageSearch({ skip, take })} onShowSizeChange={(skip, take) => onStorePageSearch({ skip, take })} />
      </Layout.Footer>
    </Layout>
  );
};
