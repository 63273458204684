import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

export const PageNotFound: FC = () => {
  const navigate = useNavigate();

  const onPageNotFound = useCallback(() => {
    message.error('찾을 수 없는 페이지입니다.');
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    onPageNotFound();
  }, [onPageNotFound]);

  return <div />;
};
