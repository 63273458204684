import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { StoreDetailTab } from './StoreDetailTab';
import { StoreReviewTab } from './StoreReviewTab';

const Container = styled.div`
  padding-left: 10px;
`;

export const StoreDetail: React.FC = () => {
  return (
    <Container>
      <Tabs defaultActiveKey="store" onChange={() => {}}>
        <Tabs.TabPane tab="매장정보" key="store">
          <StoreDetailTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="매장리뷰" key="review">
          <StoreReviewTab />
        </Tabs.TabPane>
      </Tabs>
    </Container>
  );
};
