import React, { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { Button, Form, Image, Input, message, Select, Switch } from 'antd';
import { postStore } from '../../../APIs/storeAPI';
import { GPSAddress, KaKaoMapGPSSearch } from '../../../components/KaKaoMapGPSSearch';
import { useStoreTypes } from '../../../module/hooks/useStoreTypes';
import { useStoreCategories } from '../../../module/hooks/useStoreCategories';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../../APIs/API';
import { Uploader } from '../../../components/uploader';
import axios from 'axios';

type StoreCreateForm = {
  name: string;
  latitude: string;
  longitude: string;
  isParking: boolean;
  description: string;
  address: string;
  operations: string[];
  phone: string;
  webSite: string;
  instagram: string;
  blog: string;
  s3_thumbnail?: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const LeftContainer = styled.div`
  width: 30%;
  margin-right: 30px;
`;

const RightContainer = styled.div``;

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubmitFinishWrapper = styled.div`
  & > :first-child {
    margin-right: 5px;
  }
`;

export const StoreCreate: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [store, setStore] = useState<StoreCreateForm>({
    name: '',
    latitude: '0',
    longitude: '0',
    isParking: false,
    description: '',
    address: '',
    operations: [],
    phone: '',
    webSite: '',
    instagram: '',
    blog: '',
  });
  const [typeId, setTypeId] = useState<number>(1);
  const [categoryIds, setCategoryIds] = useState<number[]>([]);

  const { types, isTypesFetching } = useStoreTypes();
  const { categories, isCategoriesFetching } = useStoreCategories();

  const onValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      store && setStore({ ...store, [e.target.name]: e.target.value });
    },
    [store],
  );

  const onMarkerSelected = useCallback(
    (address: GPSAddress) => {
      store && setStore({ ...store, latitude: address.y, longitude: address.x });
    },
    [store],
  );

  const onTypeChange = useCallback((value: number) => {
    setTypeId(value);
  }, []);

  const onCategoryChange = useCallback((value: number[]) => {
    setCategoryIds(value);
  }, []);

  const onOperationChange = useCallback(
    (text: string, index: number) => {
      const nextOperation = [...store.operations];

      nextOperation[index] = text;

      store && setStore({ ...store, operations: nextOperation });
    },
    [store],
  );

  const onParkingChange = useCallback(
    (checked: boolean, event: MouseEvent<HTMLButtonElement>) => {
      store && setStore({ ...store, isParking: checked });
    },
    [store],
  );

  const onThumbnailChange = useCallback(
    (path: string) => {
      store && setStore({ ...store, s3_thumbnail: path });
    },
    [store],
  );

  const onSave = useCallback(async () => {
    if (!store.name) {
      return message.warning('매장명을 입력해주세요');
    }

    if (categoryIds.length === 0) {
      return message.warning('키워드를 선택해주세요');
    }

    try {
      await postStore({
        address: store.address,
        blog: store.blog,
        description: store.description,
        instagram: store.instagram,
        isParking: store.isParking,
        latitude: +store.latitude,
        longitude: +store.longitude,
        name: store.name,
        operations: store.operations,
        phone: store.phone,
        webSite: store.webSite,
        categoryIds,
        typeIds: [typeId],
        s3_thumbnail: store.s3_thumbnail ? store.s3_thumbnail : undefined,
      });
      message.success('등록 되었습니다');
      navigate(-1);
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data.statusCode === 406) {
          message.error('이미 등록된 매장명입니다');
        } else {
          message.error('등록에 실패하였습니다');
        }
      }
    }
  }, [store, typeId, categoryIds]);

  return (
    <Container>
      <LeftContainer>
        <Form layout="horizontal" form={form} labelCol={{ sm: 5 }} onFinish={onSave}>
          <Form.Item label="매장명" rules={[{ required: true }]}>
            <Input name="name" value={store.name} onChange={onValueChange} />
          </Form.Item>
          <Form.Item label="업종분류">
            <Select
              style={{
                width: '100%',
              }}
              defaultValue={typeId}
              onChange={onTypeChange}
              loading={isTypesFetching}
            >
              {types.map(({ id, name }) => (
                <Select.Option key={`store-type-${id}`} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="키워드">
            <Select mode="multiple" showArrow onChange={onCategoryChange} defaultValue={categoryIds} loading={isCategoriesFetching}>
              {categories.map(({ id, name }) => (
                <Select.Option key={`store-category-${id}`} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="상세설명">
            <Input.TextArea name="description" value={store.description} rows={3} onChange={onValueChange} />
          </Form.Item>
          <Form.Item label="주소" rules={[{ required: true }]}>
            <Input name="address" value={store.address} onChange={onValueChange} />
          </Form.Item>
          <Form.Item label="위도" name="latitude">
            <div>
              <Input disabled name="latitude" value={store.latitude} />
              <input type="text" name="latitude" value={store.latitude} onChange={onValueChange} hidden={true} />
            </div>
          </Form.Item>
          <Form.Item label="경도" name="longitude">
            <div>
              <Input disabled name="longitude" value={store.longitude} />
              <input type="text" name="latitude" value={store.latitude} onChange={onValueChange} hidden={true} />
            </div>
          </Form.Item>
          <Form.Item label="운영시간" style={{ display: 'flex', alignItems: 'center' }}>
            {['평일', '토요일', '일요일', '휴무일'].map((operation, index) => (
              <Form.Item key={operation} label={operation}>
                <Input
                  value={store.operations[index]}
                  onChange={(e) => {
                    onOperationChange(e.target.value, index);
                  }}
                />
              </Form.Item>
            ))}
          </Form.Item>
          <Form.Item label="연락처">
            <Input name="phone" value={store.phone} onChange={onValueChange} />
          </Form.Item>
          <Form.Item label="웹사이트">
            <Input name="webSite" value={store.webSite} onChange={onValueChange} />
          </Form.Item>
          <Form.Item label="인스타그램">
            <Input name="instagram" value={store.instagram} onChange={onValueChange} />
          </Form.Item>
          <Form.Item label="블로그">
            <Input name="blog" value={store.blog} onChange={onValueChange} />
          </Form.Item>
          <Form.Item label="주차">
            <Switch checked={store.isParking} onClick={onParkingChange} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 5 }}>
            <SubmitWrapper>
              <SubmitFinishWrapper>
                <Button type="primary" htmlType="submit">
                  등록
                </Button>
                <Button onClick={() => navigate(-1)}>취소</Button>
              </SubmitFinishWrapper>
            </SubmitWrapper>
          </Form.Item>
        </Form>
      </LeftContainer>
      <RightContainer>
        <div>
          {store.s3_thumbnail && <Image src={store.s3_thumbnail} width={300} />}
          <Uploader actionUrl={`${baseURL}/file/store`} onUploadedImageCallback={onThumbnailChange} />
        </div>
        <KaKaoMapGPSSearch address={store.address} onMarkerSelected={onMarkerSelected} mapSize={500} />
      </RightContainer>
    </Container>
  );
};
