import { getStoreTypes } from '../../APIs/storeAPI';
import { useQuery } from '@tanstack/react-query';

export const useStoreTypes = () => {
  const { data, isLoading } = useQuery(['store', 'types'], getStoreTypes, { staleTime: 1000 * 60 * 30 });

  return {
    types: data || [],
    isTypesFetching: isLoading,
  };
};
