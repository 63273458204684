import { Paging } from '../../pages/store';
import { API } from '../API';

export type MetaV1 = {
  total: number;
};

export type UserV1 = {
  createdAt: string;
  email: string;
  id: number;
  nickName: string;
  updatedAt: string | null;
};

export type ImageV1 = {
  id: number;
  name: string;
  createdAt: string;
  originalName: string;
  path: string;
  size: number;
  type: string;
  updatedAt: string | null;
};

export type StoreReviewV1 = {
  id: number;
  description: string;
  rate: number;
  createdAt: string;
  updatedAt: string | null;
  images: ImageV1[];
  user: UserV1;
};

export type GetStoreReviewsResponse = {
  storeReviews: StoreReviewV1[];
  meta: MetaV1;
};

export const getStoreReviews = async (storeId: number, paging: Paging) => {
  const res = await API.get<GetStoreReviewsResponse>(`/store-review?storeId=${storeId}&take=${paging.take}&skip=${paging.skip}`);

  return res.data;
};
