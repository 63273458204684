import axios from 'axios';
import { message } from 'antd';

export const baseURL = process.env.REACT_APP_API_HOST;

const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (axios.isAxiosError(error)) {
      const response = error.response;

      if (response?.status === 401) {
        message.error('재로그인 후 다시 시도해주세요.');
      }
    }
  },
);

export const setAuthorizationHeader = (token?: string | null): void => {
  instance.defaults.headers.common.Authorization = `Bearer ${token || ''}`;
};

export const API = instance;
