import { getStoreCategories } from '../../APIs/storeAPI';
import { useQuery } from '@tanstack/react-query';

export const useStoreCategories = () => {
  const { data, isLoading } = useQuery(['store', 'categories'], getStoreCategories, { staleTime: 1000 * 60 * 30 });

  return {
    categories: data || [],
    isCategoriesFetching: isLoading,
  };
};
