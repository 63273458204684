import { API } from '../API';
import { GetStoreCategoriesResponse, GetStoreModifyResponse, GetStoresParams, GetStoresResponse, GetStoreTypesResponse, PostStoreParams, PutStoreParams, StoreResponseV1 } from './types';
import { STORE } from './consts';

export * from './types';
export * from './query';
export * from './consts';
export * from './mutation';

export const getStores = async ({ name, take, skip, status = STORE.STATUS.APPROVED }: GetStoresParams) => {
  const skipPage = skip - 1;

  const res = await API.get<GetStoresResponse>(`admin/store?name=${name}&take=${take}&skip=${skipPage}&status=${status}`);

  return res.data;
};

export const getStore = async (storeId: number) => {
  const res = await API.get<StoreResponseV1>(`admin/store/${storeId}`);
  return res.data;
};

export const postStore = async (params: PostStoreParams) => {
  const res = await API.post('admin/store', params);

  return res.data;
};

export const putStore = async (storeId: number, store: PutStoreParams) => {
  return await API.put(`admin/store/${storeId}`, store);
};

export const deleteStore = async (storeId: number) => {
  return await API.delete(`admin/store/${storeId}`);
};

export const getStoreModify = async () => {
  const res = await API.get<GetStoreModifyResponse>('/store-modify');

  return res.data;
};

export const getStoreTypes = async () => {
  const res = await API.get<GetStoreTypesResponse>('/store-type');

  return res.data;
};

export const getStoreCategories = async () => {
  const res = await API.get<GetStoreCategoriesResponse>('/store-category');

  return res.data;
};
