import React, { ReactElement } from 'react';
import { Image, Table } from 'antd';
import { Link } from 'react-router-dom';
import { baseURL } from '../../APIs/API';
import { IMAGE_FALLBACK } from '../../shared/common';
import { GetStoresResponseStore } from '../../APIs/storeAPI';

type Required = {
  key: number;
  id: number;
};

type Props = {
  stores: (Required & GetStoresResponseStore)[];
  path: string;
  loading?: boolean;
};

export const StoresListTable = (props: Props): ReactElement => {
  return (
    <Table dataSource={props.stores} pagination={false} loading={props.loading}>
      <Table.Column<GetStoresResponseStore>
        key="thumbnail"
        dataIndex="thumbnail"
        title="대표 이미지"
        width={150}
        render={(value, store) => {
          const thumbnailUrl = store.s3_thumbnail ? store.s3_thumbnail : `${baseURL}/${value}`;
          return <Image width={100} src={thumbnailUrl} fallback={IMAGE_FALLBACK} />;
        }}
      />
      <Table.Column<Required>
        key="name"
        dataIndex="name"
        title="매장명"
        width={200}
        render={(value, record) => {
          return <Link to={`${props.path}/${record.id}`}>{value}</Link>;
        }}
      />
      <Table.Column key="description" dataIndex="description" title="상세설명" ellipsis={true} />
      <Table.Column key="address" dataIndex="address" title="주소" width={200} ellipsis={true} />
      <Table.Column key="phone" dataIndex="phone" title="연락처" width={170} />
      <Table.Column
        key="webSite"
        dataIndex="webSite"
        title="웹사이트"
        ellipsis={true}
        width={150}
        render={(value) =>
          value && (
            <a href={value} rel="noreferrer" target="_blank">
              {value}
            </a>
          )
        }
      />
      <Table.Column
        key="blog"
        dataIndex="blog"
        title="블로그"
        ellipsis={true}
        width={150}
        render={(value) =>
          value && (
            <a href={value} rel="noreferrer" target="_blank">
              {value}
            </a>
          )
        }
      />
      <Table.Column key="createdAt" dataIndex="createdAt" title="등록일자" width={120} />
    </Table>
  );
};
