import React, { useCallback, useState } from 'react';
import { useFetchStoresQuery } from '../../APIs/storeAPI';
import moment from 'moment';
import { Button, Input, Layout, Pagination } from 'antd';
import { paths } from '../paths';
import styled from 'styled-components';
import { StoresListTable } from '../../components/stores/StoresListTable';
import { PER_PAGE } from '../../shared/pagination';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;

export type Paging = {
  take: number;
  skip: number;
};

const LayoutHeader = styled(Layout.Header)`
  background-color: #ffffff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LayoutFooter = styled(Layout.Footer)`
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
`;

export const Store: React.FC = () => {
  const navigate = useNavigate();
  const [filterName, setFilterName] = useState('');
  const [filterPage, setFilterPage] = useState<Paging>({
    take: PER_PAGE,
    skip: 1,
  });

  const { isLoading, data } = useFetchStoresQuery({ name: filterName, ...filterPage });

  const onStoreNameSearch = useCallback((name: string) => {
    setFilterName(name);
  }, []);

  const onStorePageSearch = useCallback((paging: Paging) => {
    setFilterPage(paging);
  }, []);

  const onMoveToCreatePage = useCallback(() => navigate(paths.store.create), []);

  if (!data?.stores || !data?.meta) {
    return null;
  }

  return (
    <Layout>
      <LayoutHeader>
        <Search placeholder="매장명 검색" onSearch={onStoreNameSearch} enterButton style={{ width: 200 }} />
        <span>전체 매장 수: {data.meta.total}</span>
      </LayoutHeader>
      <Layout.Content>
        <StoresListTable
          stores={data.stores.map((store) => {
            return { ...store, key: store.id, createdAt: moment(store.createdAt).format('YYYY-MM-DD') };
          })}
          path={paths.store.root}
          loading={isLoading}
        />
      </Layout.Content>
      <LayoutFooter>
        <Pagination current={filterPage.skip} pageSize={filterPage.take} total={data.meta.total} onChange={(skip, take) => onStorePageSearch({ skip, take })} onShowSizeChange={(skip, take) => onStorePageSearch({ skip, take })} />
        <Button type="primary" onClick={onMoveToCreatePage}>
          등록
        </Button>
      </LayoutFooter>
    </Layout>
  );
};
