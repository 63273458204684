import { API } from '../API';

type PutLoginParams = {
  email: string;
  password: string;
};

export type PutLoginResponse = {
  accessToken: string;
  email: string;
  id: number;
  nickName: string;
};

export const putLogin = async (params: PutLoginParams) => {
  const res = await API.post<PutLoginResponse>('/user/admin/login', params);

  return res.data;
};
