import { Button, Form, Input, message } from 'antd';
import styled from 'styled-components';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { putLogin } from '../APIs/userAPI';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../module/user';
import { emailRegex, passwordRegex } from '../shared/common';
import axios from 'axios';
import { setAuthorizationHeader } from '../APIs/API';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

type Account = {
  email: string;
  password: string;
};

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onUserLogin } = useUser();

  const [account, setAccount] = useState<Account>({
    email: '',
    password: '',
  });

  const from = useMemo(() => {
    const state = location.state as { from: { pathname: string } };

    if (state && state.from) {
      return state.from.pathname;
    }

    return '/';
  }, [location]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAccount({ ...account, [e.target.name]: e.target.value });
    },
    [account],
  );

  const onLogin = useCallback(async () => {
    try {
      const result = await putLogin(account);
      onUserLogin(result);
      setAuthorizationHeader(result.accessToken);
      navigate(from);
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data.statusCode === 400) {
          message.warning('아이디 혹은 비밀번호가 일치하지 않습니다');
        }
      }
    }
  }, [from, account]);

  const isConfirmed = useMemo(() => emailRegex.test(account.email) && passwordRegex.test(account.password), [account]);

  return (
    <Container>
      <Form onFinish={onLogin}>
        <Form.Item
          label="이메일"
          name="username"
          rules={[
            {
              required: true,
              message: '이메일 주소를 입력해주세요',
            },
          ]}
        >
          <Input name="email" value={account.email} onChange={onChange} />
        </Form.Item>
        <Form.Item
          label="비밀번호"
          name="password"
          rules={[
            {
              required: true,
              message: '비밀번호를 입력해주세요',
            },
          ]}
        >
          <Input name="password" type="password" value={account.password} onChange={onChange} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!isConfirmed}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};
